<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :fullscreen="$vuetify.display.mobile"
  >
    <v-card>
      <v-card-title class="pa-4 d-flex">
        Selecionar Fornecedor
        <v-spacer></v-spacer>
        <AppAddBtn size="small" @click="handleAddNew()">Novo</AppAddBtn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-4">
        <v-row class="mb-2">
          <v-col>
            <AppSearchField
              v-model="query.search"
              @update:modelValue="search(1)"
              :loading="loading"
            />
          </v-col>
        </v-row>

        <AppPagination
          :data="suppliers"
          v-model="query.page"
          @update:modelValue="search()"
        >
          <v-list class="py-0">
            <template v-for="supplier of suppliers.data">
              <v-list-item class="px-2" @click="handleSelect(supplier)">
                {{ supplier.name }}
                <template v-slot:append>
                  <v-icon color="disabled" icon="mdi:mdi-chevron-right" />
                </template>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-list>
        </AppPagination>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          class="text-capitalize"
          variant="plain"
          text
          @click="dialog = false"
        >
          <v-icon class="mr-2" icon="mdi:mdi-arrow-left" />
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <app-text-field
    :modelValue="selectText"
    @click="open()"
    label="Fornecedor"
    readonly
     v-bind="$attrs"
    :clearable="isReadonly"
    @click:clear="handleClear()"
  >
    <template v-slot:append-inner>
      <v-icon v-if="isReadonly" icon="mdi:mdi-chevron-right" @click="open()" />
    </template>
  </app-text-field>

  <SupplierForm ref="form" @store="handleStore($event)" />
</template>

<script setup>
const props = defineProps(["modelValue", "readonly"]);

const $attrs = useAttrs();

const emit = defineEmits(["update:modelValue"]);

const form = ref(null);

const dialog = ref(false);

const supplierStore = useSupplierStore();

const { suppliers, query } = storeToRefs(supplierStore);

const loading = ref(false);

const select = computed({
  get() {
    if (props.modelValue && props.modelValue.id) {
      return props.modelValue;
    } else {
      return null;
    }
  },

  set(value) {
    if (value) {
      emit("update:modelValue", { id: value.id, name: value.name });
      return;
    }
    emit("update:modelValue", null);
  },
});

const selectText = computed(() => {
  if (select.value) {
    return select.value.name;
  } else {
    return null;
  }
});

const isReadonly = computed(() => props.readonly || true);

const search = async (page = null) => {
  if (page) {
    query.value.page = page;
  }

  loading.value = true;

  await supplierStore.index();

  loading.value = false;
};

const open = () => {
  if (isReadonly.value) {
    supplierStore.$reset();

    query.value.status = 1;

    search(1);

    dialog.value = true;
  }
};

const handleSelect = (supplier) => {
  select.value = supplier;

  dialog.value = false;
};

const handleClear = () => {
  select.value = null;

  dialog.value = false;
};

const handleAddNew = () => {
  form.value.open();
};

const handleStore = (item) => {
  handleSelect(item);
};
</script>