import { defineStore } from "pinia";

import { useSupplierStore } from "./supplier";
import { useOnboardingStore } from "../app/onboarding";

export const useSupplierStoreForm = defineStore("supplierStoreForm", {

	state: () => ({

		onboardingStore: useOnboardingStore(),

		supplierStore: useSupplierStore(),

		form: {
			code: null,
			name: null,
			alias: null,
			person_type: 'juridica',
			cpf: null,
			rg: null,
			cnpj: null,
			inscricao_estadual: null,
			inscricao_estadual_isento: null,
			phone: null,
			phone_wa: null,
			email: null,
			notes: null,

			status: true,

			main_address: {
				type: "main",
				postal_code: null,
				country: null,
				state: null,
				city: null,
				address: null,
				number: null,
				complement: null,
				district: null,
				status: true,
			},
		},

	}),

	getters: {

		title() {
			return this.form.id ? "Editar Fornecedor" : "Novo Fornecedor";
		},


		IsJuridic() {
			return this.form.person_type == "juridica";
		},

		isInsert() {
			return this.form.id == null;
		},

		alias_label() {
			return this.IsJuridic ? "Nome Fantasia" : "Apelido";
		},

		name_label() {
			return this.IsJuridic ? "Razão Social" : "Nome";
		},

	},


	actions: {

		setCompanyData(company) {
			this.form = {
				...this.form,
				...company,
			};
		},

		setAddressData(address) {
			this.form.main_address.city = address.city;
			this.form.main_address.state = address.state;
			this.form.main_address.city_code = address.city_code;
		},

		async show(id) {

			await this.supplierStore.show(id).then((response) => {
				this.form = response
			}).catch((error) => { });
		},

		async save() {

			if (this.isInsert) {
				var supplier = await this.supplierStore.store(this.form);
			}

			if (!this.isInsert) {
				var supplier = await this.supplierStore.update(this.form);
			}

			this.onboardingStore.completeStep('create_first_supplier')

			return supplier
		},
	}
});
