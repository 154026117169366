import { defineStore } from "pinia";


export const useSupplierStore = defineStore("supplierStore", {

	state: () => ({

		http: useNuxtApp().$http,

		query: {
			search: "",
			page: 1,
			status: null,
			statuses: [1],
			orderBy: "name",
			orderDirection: "asc",
		},

		suppliers: {
			data: []
		},
	}),

	actions: {

		async index() {

			const data = await this.http.index("supplier/supplier", this.query);

			this.suppliers = data.suppliers;
		},

		async show(id) {

			const data = await this.http.show("supplier/supplier", id);

			return data.supplier;

		},

		async store(form) {

			return await this.http.store("supplier/supplier", form);

		},

		async update(form) {

			return await this.http.update("supplier/supplier", form.id, form);

		},

		async birthdaysOfMonth(query) {

			const data = await this.http.index("supplier/supplier", query);

			return data.suppliers;

		}
	}
});
